<template>
  <div class="mailRules">
    <ShopsAndSites
      v-if="shopList.length != 0"
      placeholder="请选择店铺"
      :clearable="false"
      style="margin-right: 10px"
      :list="shopList"
      @getShopId="getID"
    ></ShopsAndSites>
    <el-select
      v-model="pageInfo.status"
      placeholder="规则状态"
      style="width: 130px; margin-right: 10px"
    >
      <el-option
        v-for="item in ruleStatus"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="pageInfo.type"
      placeholder="邮件类型"
      style="width: 130px; margin-right: 10px"
    >
      <el-option
        v-for="item in careMailType"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="pageInfo.createBy"
      placeholder="全部创建人"
      style="width: 130px; margin-right: 10px"
    >
      <el-option
        v-for="item in createManList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="pageInfo.updateBy"
      placeholder="全部操作人"
      style="width: 150px; margin-right: 10px"
    >
      <el-option
        v-for="item in operateManList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <TimeQuantum
      style="margin-right: 10px"
      @selectFinish="getTimeSection"
    ></TimeQuantum>
    <el-input
      style="width: 150px; margin-right: 10px; margin-top: 5px"
      placeholder="搜索模板名称、邮件主题"
    ></el-input>
    <el-button type="primary" @click="find">查询</el-button>
    <el-button>重置</el-button><br />
    <el-button
      @click="addTypeBox = true"
      style="margin-top: 10px; margin-right: 10px"
      type="primary"
      >新建规则</el-button
    >
    <el-dropdown trigger="click">
      <el-button style="margin-top: 10px"
        >批量操作 <i class="el-icon-arrow-down"></i
      ></el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="batch('enabled', '开启')"
          >批量开启</el-dropdown-item
        >
        <el-dropdown-item @click.native="batch('disabled', '禁用')"
          >批量禁用</el-dropdown-item
        >
        <el-dropdown-item @click.native="batch('delete', '永久删除')"
          >批量删除</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <el-table
      v-loading="loading"
      :data="tableData"
      @selection-change="batchselect"
      style="width: 100%; margin-top: 10px"
      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
      :header-cell-style="{
        fontSize: '13px',
        padding: '9px 0',
        marginTop: '10px',
        background: '#EBEEF5',
      }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="模板名称"> </el-table-column>
      <el-table-column prop="subject" label="邮件主题"> </el-table-column>
      <el-table-column prop="country" label="国家"> </el-table-column>
      <el-table-column prop="shopName" label="店铺"> </el-table-column>
      <el-table-column prop="statusName" label="状态">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatuse(scope.row)"
            v-model="scope.row.status"
            active-color="#0ABF5B"
            inactive-color="#909399"
            active-value="enabled"
            inactive-value="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="邮件类型"> </el-table-column>
      <el-table-column prop="createName" label="创建人"> </el-table-column>
      <el-table-column prop="updateName" label="操作人"> </el-table-column>
      <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
      <el-table-column prop="prop" label="操作">
        <template slot-scope="scope">
          <el-button
            @click="getUpdateInit(scope.row.id)"
            type="text"
            style="margin-right: 10px"
            >编辑</el-button
          >
          <el-dropdown trigger="click" placement="bottom">
            <el-button type="text"
              >操作 <i class="el-icon-arrow-down"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="del(scope.row.id)"
                >删除</el-dropdown-item
              >
              <el-dropdown-item @click.native="getUpdateInit(scope.row.id,'add')">复制新增</el-dropdown-item>
              <el-dropdown-item @click.native="tableSendShow(scope.row.id,scope.row.name)"
                >发送预览</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 10px">
      <el-pagination
        @size-change="tableSizeChange"
        @current-change="tableCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="PreviewType"
      :visible.sync="tableSendView"
      width="width"
      append-to-body
      :before-close="tableSendViewClose"
    >
      <div>
        <h3>{{sendViewName}}</h3>
        <br />
        <el-input
        v-model="sendViewInfo.keyword"
          placeholder="搜索订单号"
          style="width: 200px; margin-right: 10px"
        ></el-input>
        <el-button type="primary" @click="findSendView">查询</el-button>
        <el-table
          :data="viewData"
          style="width: 100%; margin-top: 10px; min-height: 400px"
          :cell-style="{ fontSize: '13px', padding: '7px 0' }"
          :header-cell-style="{
            fontSize: '13px',
            padding: '5px',
            marginTop: '10px',
            background: '#EBEEF5',
          }"
        >
          <el-table-column prop="amazonOrderNumber" label="订单号"> </el-table-column>
          <el-table-column prop="shopName" label="店铺"> </el-table-column>
          <el-table-column prop="purchaseDate" label="订购时间"> </el-table-column>
          <el-table-column prop="deliveryDate" label="发货时间"> </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-pagination
            @size-change="sendViewSizeChange"
            @current-change="sendViewCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="sendViewTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="tableSendViewClose">取 消</el-button>
        <el-button type="primary" @click="tableSendViewClose"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="新建规则"
      custom-class="type"
      :visible.sync="addTypeBox"
      width="500px"
      :before-close="dialogBeforeClose"
    >
      <div class="Rules">
        <div @click="showAddBox('亚马逊')" style="width: 100%" class="select">
          <div class="iconfont icon-amazon"></div>
          <div>
            <h3>亚马逊邮件</h3>
            <p>
              通过亚马逊api发送成功率较高，不收取费用；
              可用于确认定制信息，确认订单详情, 说明意外问题，请求移除差评。
            </p>
          </div>
        </div>
        <div @click="showAddBox('自定义')" style="width: 100%" class="select">
          <div class="el-icon-edit"></div>
          <div>
            <h3>自定义邮件</h3>
            <p>
              支持自定义邮件内容，根据发送数量收费;
              注意你仅能为了完成订单或回复顾客的询问而联系买家，在邮件中不能包含营销或促销内容。
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      custom-class="Box"
      :destroy-on-close="true"
      :title="title == '编辑' || isUpdate ? '编辑规则' : `新建${title}邮件`"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
      :before-close="dialogVisibleClose"
    >
      <el-dialog
        title="邮件预览"
        :visible.sync="previewBox"
        width="900px"
        append-to-body
        :before-close="dialogBeforeClose"
      >
        <div v-if="title == '亚马逊'">
          <div
            v-if="previewTitle"
            style="padding: 10px 20px 10px 10px; background: #f0f6ff"
          >
            邮件预览页面收件人，店铺名称，订单信息为虚拟数据，实际发送将以真实订单信息和国家语言为准，请放心使用
            <span
              @click="previewTitle = false"
              class="el-icon-close"
              style="float: right; margin-left: 10px; cursor: pointer"
            ></span>
          </div>
          <p style="text-align: center; padding: 15px; font-size: 17px">
            You received a message from Amazon Seller-DIY
          </p>
          <p style="text-align: center; padding: 15px; font-size: 17px">
            order ID：333-7777777-7777777
          </p>
          <hr />
          <p style="padding: 13px 30px; font-size: 17px">
            Count Product Name and ASIN
          </p>
          <hr />
          <div style="display: flex; align-items: center">
            <span style="font-size: 20px; padding: 0 20px">1</span>
            <div
              style="
                font-size: 50px;
                background: #f0f6ff;
                width: 120px;
                height: 120px;
                text-align: center;
                line-height: 120px;
              "
              class="iconfont icon-combine-manage"
            ></div>
            <div style="margin-left: 20px">
              <p style="font-size: 17px; line-height: 30px">
                DIY InnoGear CT004
              </p>
              <p>ASIN: B02S123456</p>
            </div>
          </div>
          <hr />
          <br />
          <p style="font-size: 17px">Hi Customer:</p>
          <br />
          <p style="font-size: 17px; line-height: 25px">
            Thank you for shopping with us. Amazon seller DIY has encountered an
            unexpected problem with completing your order.
          </p>
          <br />
          <div style="padding: 20px; border: 1px solid gray">
            <h3>Message from seller DIY:</h3>
            <p style="padding: 20px 0">{{oneInfo.content}}</p>
          </div>
          <br />
          <p style="font-size: 17px; line-height: 25px">
            You can send an anonymous message to the seller DIY, that does not
            expose your real email address, by replying to this email.
          </p>
          <p style="font-size: 17px; line-height: 25px">
            If you were contacted inappropriately by the seller, please report
            this message.
          </p>
          <p style="font-size: 17px; line-height: 25px">
            We hope to see you again soon.
          </p>
        </div>
        <div
          v-if="title == '自定义'"
          style="background: #f7f8fa; border: 1px solid #e6e8ed"
        >
          <p
            style="
              text-align: center;
              padding: 30px;
              font-size: 17px;
              border-bottom: 1px solid #e6e8ed;
            "
          >
            {{oneInfo.subject}}
          </p>
          <div v-html="oneInfo.content" style="min-height: 400px; padding: 15px"></div>
        </div>
        <div slot="footer">
          <el-button @click="previewBox = false">关闭</el-button>
        </div>
      </el-dialog>

      <!-- 发送测试邮件 -->
      <el-dialog
        title="发送测试邮件"
        :visible.sync="sendTestBox"
        width="900px"
        append-to-body
        :before-close="sendTestBoxClose"
      >
        <div v-loading='dialogLoading'>
          <p style="color: #999999">
            *填写邮箱地址你会收到与买家相同的邮件，用来检验你设置的邮件内容是否有误。
          </p>
          <el-form
            style="margin-top: 20px;width:100%"
            ref="sendAPreview"
            :inline="true"
            :model="sendAPreview"
            label-width="80px"
          >
            <el-form-item label="收件邮箱"
            prop="toArr"
            :rules="[{ required: true, message: '请输入邮箱地址', trigger: 'blur' },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
              <el-input v-model="sendAPreview.toArr" style="width:200px" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="发件邮箱">
              <el-select v-model="sendAPreview.accountId" placeholder="请选择发件邮箱">
                <el-option
                  v-for="item in accountList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择订单" >
              <div style="border: 1px solid #dadce0; padding: 10px">
                <ShopsAndSites
                  v-if="shopList.length != 0"
                  placeholder="请选择店铺"
                  :clearable="false"
                  style="margin-right: 10px"
                  :list="shopList"
                  @getShopId="getSendAPreviewID"
                ></ShopsAndSites>
                <el-input
                v-model="sendAPreview.keyword"
                  placeholder="搜索内容"
                  style="width: 290px; margin-right: 10px; margin-top: 1px"
                >
                  <el-select
                  v-model="sendAPreview.selectType"
                    slot="prepend"
                    placeholder="搜索类型"
                    style="width: 100px"
                  >
                    <el-option label="订单号" value="orderAmazonNumber"> </el-option>
                    <el-option label="ASIN" value="asin"> </el-option>
                    <el-option label="商品名" value="title"> </el-option>
                  </el-select>
                </el-input>
                <el-button @click="findChooseOrdersPage" type="primary">查询</el-button>
                <el-table
                  :data="sendAPreview.list"
                  height="500px"
                  ref="orderOn"
                  style="width: 700px; margin-top: 10px"
                  :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                  :header-cell-style="{
                    fontSize: '13px',
                    padding: '5px',
                    marginTop: '10px',
                    background: '#EBEEF5',
                  }"
                >
                  <el-table-column
                    width="80px"
                    prop="prop"
                    label="选择" >
                    <template slot-scope="scope">
                      <el-radio v-model="sendAPreview.orderNumber" :label="scope.row.amazonOrderNumber">{{ }}</el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column
                    header-align="center"
                    align="center"
                    width="165px"
                    prop="amazonOrderNumber"
                    label="订单号"
                  >
                  </el-table-column>
                  <el-table-column
                    header-align="center"
                    align="center"
                    prop="prop"
                    label="商品信息"
                  >
                  <template slot-scope="scope">
                    <el-popover
                      placement="right"
                      width="450"
                      trigger="click">
                      <el-table 
                      :data="scope.row.itemList"
                      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                      :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                      }">
                        <el-table-column width="250" property="date" label="商品信息">
                          <template slot-scope="scope">
                            <GoodsImage
                              :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                              :label="scope.row.label"
                              :parentASIN="scope.row.parentAsin"
                              :imageUrl="scope.row.imageUrl"
                              :asin="scope.row.asin"
                              :title="scope.row.title"
                              :commoditySku="scope.row.commoditySku"
                            ></GoodsImage>
                          </template>
                        </el-table-column>
                        <el-table-column width="90" prop="salePrice" label="单价">
                          <template slot-scope="scope">
                            {{sendAPreview.currency}} {{scope.row.salePrice}}
                          </template>
                        </el-table-column>
                        <el-table-column width="100" prop="quantityOrdered" label="数量"></el-table-column>
                      </el-table>
                      <div slot="reference" style="overflow:hidden;width:40px;margin:0 auto;position:relative">
                        <div style="position:absolute;width:14px;height:14px;top:0px;right:0px;background:#409EFF;color:white;font-size:12px;border-radius:50%;text-align:center;line-height:14px">{{scope.row.itemList.length}}</div>
                        <img :src="scope.row.itemList[0].imageUrl" alt="" width="100%">
                      </div>
                    </el-popover>

                    
                  </template>
                  </el-table-column>
                  <el-table-column
                    header-align="center"
                    align="center"
                    prop="shopName"
                    label="店铺"
                  >
                  </el-table-column>
                  <el-table-column
                    header-align="center"
                    align="center"
                    prop="purchaseDate"
                    label="订购时间"
                  >
                  </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 10px">
                  <el-pagination
                    @current-change="sendAPreviewCurrentChange"
                    :current-page="sendAPreview.current"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    background
                    layout="total, prev, pager, next"
                    :total="sendAPreview.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div slot="footer" style="text-align: right">
            <el-button @click="sendTestBoxClose">取消</el-button>
            <el-button @click="sendTestMail" type="primary">发送</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="选择订单号"
        :visible.sync="OrderDialog"
        width="width"
        append-to-body
        :before-close="OrderDialogClose"
      >
        <div v-loading="dialogLoading">
          <el-input
            style="width: 340px; margin-right: 10px"
            v-model="orderListInfo.keyword"
            placeholder="请输入"
          >
            <el-select
              style="width: 110px"
              slot="prepend"
              v-model="orderListInfo.selectType"
            >
              <el-option label="订单号" value="orderAmazonNumber"> </el-option>
              <el-option label="ASIN" value="asin"> </el-option>
              <el-option label="商品名" value="title"> </el-option>
            </el-select>
          </el-input>
          <el-button @click="orderfind">查询</el-button>
          <el-table
            ref="order"
            :data="orderListInfo.orderList"
            @selection-change="OrderSelect"
            style="width: 100%; margin-top: 10px"
            :cell-style="{
              fontSize: '13px',
              padding: '7px 0',
              textAlign: 'left',
            }"
            :header-cell-style="{
              fontSize: '13px',
              padding: '5px',
              marginTop: '10px',
              background: '#EBEEF5',
              textAlign: 'left',
            }"
          >
            <el-table-column type="selection" width="width"> </el-table-column>
            <el-table-column prop="prop" label="订单号">
              <template slot-scope="scope">
                <el-button type="text"
                  >{{ scope.row.amazonOrderNumber }} </el-button
                ><span>({{ scope.row.quantityOrderedAll }})</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center; margin-top: 10px">
            <el-pagination
              @current-change="orderCurrentChange"
              :current-page="orderListInfo.current"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              :pager-count="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="orderListInfo.total"
            >
            </el-pagination>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="OrderDialogClose">取 消</el-button>
          <el-button type="primary" @click="addOrder">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="选择商品"
        :visible.sync="goodsBox"
        width="1100px"
        append-to-body
        :before-close="goodsBoxClose"
        :destroy-on-close="true"
      >
        <div v-loading="dialogLoading">
          <el-select
            v-model="pageInfo.status"
            clearable
            style="width：150px;margin-right:10px"
            placeholder="请选择商品状态"
            @change="find"
          >
            <el-option
              :label="item.value"
              :value="item.id"
              v-for="item in stockpage.productStatusList"
              :key="item.id"
            ></el-option>
          </el-select>
          <el-input
            style="width: 300px; margin-right: 10px"
            placeholder="请输入"
            v-model="stockpage.keyword"
            class="input-with-select"
            @keyup.enter.native="find"
          >
            <el-select
              clearable
              style="width: 130px"
              v-model="select"
              slot="prepend"
              placeholder="选择输入类型"
            >
              <el-option
                :label="item.value"
                :value="item.id"
                v-for="item in stockpage.searchList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-input>
          <el-button type="primary" icon="el-icon-search" @click="stockfind"
            >查询</el-button
          >
          <el-table
            ref="goods"
            :data="stockpage.tableData"
            @selection-change="selectStock"
            style="width: 100%; margin-top: 10px"
            :cell-style="{
              fontSize: '13px',
              padding: '7px 0',
              textAlign: 'center',
            }"
            :header-cell-style="{
              fontSize: '13px',
              padding: '5px',
              marginTop: '10px',
              background: '#EBEEF5',
              textAlign: 'center',
            }"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="prop" label="商品">
              <template slot-scope="scope">
                <GoodsImage
                  :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                  :label="scope.row.label"
                  :parentASIN="scope.row.parentAsin"
                  :imageUrl="scope.row.imageUrl"
                  :asin="scope.row.asin"
                  :title="scope.row.title"
                  :commoditySku="scope.row.commoditySku"
                ></GoodsImage>
              </template>
            </el-table-column>
            <el-table-column prop="asin" label="ASIN"> </el-table-column>
            <el-table-column prop="statusName" label="状态"> </el-table-column>
          </el-table>
          <div style="text-align: center; margin-top: 10px">
            <el-pagination
              @current-change="stockCurrentChange"
              :current-page="stockpage.current"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="stockpage.total"
            >
            </el-pagination>
          </div>
        </div>

        <div slot="footer">
          <el-button @click="goodsBoxClose">取 消</el-button>
          <el-button type="primary" @click="addGoods">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="输入ASIN"
        :visible.sync="writeASIN"
        width="500px"
        append-to-body
        :before-close="writeASINClose"
      >
        <div>
          <p>
            共输入{{ inputNum }}个asin
            <span v-if="errorNum > 0">含{{ errorNum }}个错误格式</span>
          </p>
          <br />
          <el-input
            @input="enterASIN"
            @keydown.enter.native="enterDown"
            v-model="enterContent"
            type="textarea"
            rows="10"
            placeholder="输入ASIN按回车键分隔请勿输入其他符号及空格，两种方式添加的ASIN最多1000个"
          ></el-input>
          <div>
            <ul v-if="errorList.length > 0">
              <li
                style="color: red; padding: 3px"
                v-for="(item, index) in errorList"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="writeASINClose">取 消</el-button>
          <el-button
            type="primary"
            @click="addWriteAsin"
            :loading="dialogLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="输入订单号"
        :visible.sync="writeOrder"
        width="500px"
        append-to-body
        :before-close="writeOrderClose"
      >
        <div>
          <p>共输入{{ inputNum }}个订单号</p>
          <br />
          <el-input
            @input="enterOrder"
            @keydown.enter.native="enterDown"
            v-model="enterContent"
            type="textarea"
            rows="10"
            placeholder="输入订单号按回车键分隔请勿输入其他符号及空格，两种方式添加的ASIN最多1000个"
          ></el-input>
          <div>
            <ul v-if="errorList.length > 0">
              <li
                style="color: red; padding: 3px"
                v-for="(item, index) in errorList"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="writeOrderClose">取 消</el-button>
          <el-button
            type="primary"
            @click="addWriteOrder"
            :loading="dialogLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="批量导入"
        :visible.sync="batchEnter"
        width="600px"
        append-to-body
        :before-close="batchEnterClose"
      >
        <div>
          <el-upload
            class="upload-demo"
            :action="action2"
            :headers="{
              ...mytoken,
              shopId: shopId,
              marketPlaceId: marketPlaceId,
            }"
            :on-remove="handleRemove"
            :on-success="batchSuccess"
            multiple
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button
            >&nbsp;&nbsp;&nbsp;
            <el-button slot="tip" type="text" @click="down">下载模板</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传xsl/xlsx文件,三种方式添加的订单号合计不可超过1000个
            </div>
          </el-upload>
          <ul>
            <li
              style="padding: 6px 0"
              v-for="(item, index) in orderListInfo.errorList"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div slot="footer">
          <el-button @click="batchEnterClose">取 消</el-button>
          <el-button type="primary" @click="batchAddOrder">确 定</el-button>
        </div>
      </el-dialog>
      <div v-loading="dialogLoading" style="min-height: 600px">
        <div>
          <el-steps :active="step" simple finish-status="success">
            <el-step title="填写邮件内容" icon="iconfont icon-duihao"></el-step>
            <el-step title="选择发送对象" icon="iconfont icon-duihao"></el-step>
            <el-step title="设置规则" icon="iconfont icon-duihao"></el-step>
            <el-step title="设置发送时间" icon="iconfont icon-duihao"></el-step>
          </el-steps>
        </div>
        <!-- 第一步 -->
        <el-form
          v-show="step == 0"
          style="width: 70%; margin: 20px auto"
          ref="oneInfo"
          :model="oneInfo"
          :rules="oneInfoRules"
          label-width="100px"
        >
          <el-form-item v-if="title == '亚马逊'" label="店铺" prop="shopId">
            <ShopsAndSites
              placeholder="请选择店铺"
              :clearable="false"
              style="margin-right: 10px"
              :list="shopList"
              @getShopId="getAddId"
            ></ShopsAndSites>
          </el-form-item>
          <el-form-item prop="name">
            <template slot="label">
              <span>规则名称</span>
              <el-tooltip placement="top">
                <div slot="content" style="width: 90px">
                  模版名称会显示在邮件模版列表中，
                  只对您自己可见，不会发送给买家
                </div>
                <i class="iconfont icon-wenhao"></i>
              </el-tooltip>
            </template>
            <el-input
              style="70%"
              v-model="oneInfo.name"
              placeholder="请填写规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="规则状态" prop="status">
            <el-radio-group v-model="oneInfo.status">
              <el-radio label="enabled">开启</el-radio>
              <el-radio label="disabled">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="邮件主题"
            prop="subject"
            v-if="title == '自定义'"
          >
            <el-input
            class="inner"
            @click.native="getSubjectNum"
              v-model="oneInfo.subject"
              style="width: 80%; margin-right: 10px"
              placeholder="请输入不超过两百字"
            ></el-input>
            <el-dropdown trigger="click">
              <el-button type="text"
                >插入变量<i class="el-icon-arrow-down"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native=inputTemplate(item.id) :key="item.id" v-for="item in CareMailTemplateSubject">{{item.value}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <p>
              在向买家说明完成订单的重要问题时，为了提高发送成功率，邮件主题可参考<span
                style="color: #3390ff"
                >亚马逊政策</span
              >
            </p>
          </el-form-item>
          <el-form-item
            label="模板类型"
            prop="modelType"
            v-if="title == '亚马逊'"
          >
            <el-radio-group :disabled='isUpdate?true:false' v-model="oneInfo.modelType">
              <el-radio  label="createUnexpectedProblem">意外问题说明</el-radio>
              <el-radio label="confirmCustomizationDetails"
                >确认定制信息</el-radio
              >
              <el-radio label="createConfirmOrderDetails"
                >确认订单详情</el-radio
              >
              <el-radio
                @change="removeBad"
                label="createNegativeFeedbackRemoval"
                >请求移除差评</el-radio
              >
            </el-radio-group>
            <p style="color: #409eff; cursor: pointer">
              <i class="el-icon-warning-outline"></i> 亚马逊邮件模板使用说明
            </p>
          </el-form-item>
          <el-form-item
            label="邮件正文"
            prop="content"
            v-if="
              title == '亚马逊' &&
              oneInfo.modelType != 'createNegativeFeedbackRemoval'
            "
          >
            <el-input
              v-model="oneInfo.content"
              show-word-limit
              maxlength="2000"
              type="textarea"
              rows="15"
              placeholder="请输入"
            ></el-input>
            <p style="font-size: 12px">
              请使用店铺所在站点语言发送邮件，输入邮件内容，我们会通过亚马逊接口发送给买家。
            </p>
            <p style="font-size: 12px">
              请不要在消息中包含HTML或链接（URL）。如果需要你咨询某个特定订单或商品相关的问题，请在邮件中包含订单号，商品名称或ASIN
            </p>
          </el-form-item>
          <el-form-item
            label="邮件正文"
            prop="content"
            v-if="title == '自定义'"
          >
            <div style="width: 100%; display: flex">
              <div style="width: 80%">
                <quill-editor
                  v-model="oneInfo.content"
                  ref="myQuillEditor"
                  class="myQuillEditor"
                  :options="editorOption"
                  @focus='onEditorFocus'
                >
                </quill-editor>
              </div>
              <div
                style="
                  flex: 1;
                  border: 1px solid #cccccc;
                  border-left: 0;
                  overflow: hidden;
                "
              >
                <p style="padding: 10px 0 15px 20px">插入变量</p>

                <el-tree
                  :key="index"
                  :data="templateList"
                  :props="{label:value}"
                  node-key="id"
                  >
                  <span @click="inputVariables(data.id)" slot-scope="{ node, data }">
                    {{data.value}}
                  </span>
                </el-tree>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="
              oneInfo.modelType != 'createNegativeFeedbackRemoval' &&
              title == '自定义'
            "
          >
            <div style="float: right">
              <el-button type="text"
                ><i class="el-icon-warning-outline"></i
                >亚马逊沟通指南</el-button
              >
              <el-button type="text"
                ><i class="el-icon-warning-outline"></i>关怀邮件说明</el-button
              >
            </div>
            <el-upload
              class="upload-demo"
              :action="action"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="uploadSuccess"
              :before-remove="beforeRemove"
              :headers="mytoken"
              multiple
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small">上传附件</el-button
              >&nbsp;&nbsp;&nbsp;<span>大小不超过10M</span>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button @click="toPreview" type="text">预览</el-button>
              <el-button v-if="title=='自定义'" @click="sendTestBox=true" type="text"
                >发送测试邮件</el-button
              >
              <br /><br />
              <el-button>取消</el-button>
              <el-button @click="to2" type="primary">下一步</el-button>
            </div>
          </el-form-item>
        </el-form>
        <!-- 第二部 -->
        <el-form
          :inline="true"
          style="width: 70%; margin: 20px auto"
          v-show="step == 1"
          ref="twoInfo"
          :model="twoInfo"
          :rules="twoInfoRules"
          label-width="80px"
        >
          <el-form-item
            v-if="title == '自定义'"
            label="店铺"
            placeholder="请选择"
          >
            <ShopsAndSites
              placeholder="请选择店铺"
              :clearable="false"
              style="margin-right: 10px"
              :list="shopList"
              :coming="{ shopId: shopId, marketPlaceId: marketPlaceId }"
              @getShopId="getAddId"
            ></ShopsAndSites>
          </el-form-item>
          <el-form-item
            prop="mailAccountId"
            v-if="title == '自定义'"
            label="邮箱"
            placeholder="请选择邮箱"
          >
            <el-select v-model="twoInfo.mailAccountId" placeholder="请选择邮箱">
              <el-option
                v-for="item in mailList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select> </el-form-item
          ><br v-if="title == '自定义'" />
          <el-form-item label="发送对象">
            <el-radio-group v-model="twoInfo.selectType">
              <el-radio label="all">全部产品订单</el-radio>
              <el-radio label="inasin">购买指定ASIN的订单</el-radio>
              <el-radio label="outasin">排除购买指定ASIN的订单</el-radio>
              <el-radio label="appoint">指定订单</el-radio> </el-radio-group
            ><br />
            <div style="padding: 12px; background: #f5f7fa; max-width: 600px">
              <div v-if="twoInfo.selectType == 'appoint'">
                <el-button @click="selectOrderOpen">选择订单号</el-button>
                <el-button @click="inputType('order')">输入订单号</el-button>
                <el-button @click="batchEnter = true">批量导入</el-button>
              </div>
              <el-button
                v-if="
                  twoInfo.selectType == 'inasin' ||
                  twoInfo.selectType == 'outasin'
                "
                @click="stockPageInit"
                >选择商品</el-button
              >
              <el-button
                v-if="
                  twoInfo.selectType == 'inasin' ||
                  twoInfo.selectType == 'outasin'
                "
                @click="inputType('asin')"
                >输入ASIN</el-button
              >
              <p v-if="twoInfo.selectType != 'all'">
                已选
                <span v-if="twoInfo.selectType == 'appoint'">订单</span>
                <span
                  v-if="
                    twoInfo.selectType == 'inasin' ||
                    twoInfo.selectType == 'outasin'
                  "
                  >ASIN：</span
                >
              </p>
              <div
                v-if="
                  twoInfo.selectType == 'inasin' ||
                  twoInfo.selectType == 'outasin'
                "
                class="asinList"
              >
                <div v-for="(item, index) in twoInfo.asins" :key="item">
                  <span>{{ item }}</span>
                  <i
                    @click="twoInfo.asins.splice(index, 1)"
                    class="el-icon-close"
                  ></i>
                </div>
              </div>
              <div v-if="twoInfo.selectType == 'appoint'" class="asinList">
                <div v-for="(item, index) in twoInfo.orders" :key="item">
                  <span>{{ item }}</span>
                  <i
                    @click="twoInfo.orders.splice(index, 1)"
                    class="el-icon-close"
                  ></i>
                </div>
              </div>
              <p v-if="twoInfo.selectType == 'all'">已选择商店所有商品</p>
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <el-button>取消</el-button>
            <el-button @click="step--">上一步</el-button>
            <el-button @click="to3" type="primary">下一步</el-button>
          </el-form-item>
        </el-form>
        <!-- 第三部 -->
        <el-form
          v-show="step == 2"
          style="width: 70%; margin: 20px auto"
          :inline="true"
          ref="threeInfo"
          :model="threeInfo"
          :rules="threeInfoRules"
          label-width="80px"
          :hide-required-asterisk="true"
        >
          <el-form-item label=" " prop="getIt">
            <div style="background: #fff4e8; padding: 6px; width: 100%">
              <span style="color: red">*</span>
              根据亚马逊索评新政策，不允许使用邮件进行索评，仅能为了完成订单或回复买家的询问联系买家。
              <span>
                <el-checkbox
                  :false-label="0"
                  :true-label="1"
                  v-model="threeInfo.getIt"
                  >我知道了</el-checkbox
                >
              </span>
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox disabled checked></el-checkbox>
              订单类型
              <el-select
                v-model="threeInfo.ruleOrderSendType"
                size="mini"
                style="width: 75px"
                placeholder="FBM"
              >
                <el-option label="全部" value="ALL"></el-option>
                <el-option label="FBA" value="FBA"></el-option>
                <el-option label="FBM" value="FBM"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label=" "
            prop="ruleSameSendCheckDays"
            :rules="[
              {
                required: threeInfo.ruleSameSendCheck == 0 ? false : true,
                message: '请填写',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                @change='changeRuleSameSendCheck'
                v-model="threeInfo.ruleSameSendCheck"
              ></el-checkbox>
              <el-input
                :disabled="threeInfo.ruleSameSendCheck == 0"
                size="mini"
                style="width: 75px; margin-left: 4px"
                v-model="threeInfo.ruleSameSendCheckDays"
                placeholder="输入"
              >
              </el-input>
              天内向同一个买家只发一封邮件
            </div> </el-form-item
          ><br />
          <el-form-item label=" " prop="ruleStartAt">
            <div style="width: 300px">
              <el-checkbox disabled checked></el-checkbox>
              针对任务开启前
              <el-input
                size="mini"
                style="width: 75px"
                v-model.number="threeInfo.ruleStartAt"
                placeholder="天数"
                @blur="
                  threeInfo.ruleStartAt =
                    threeInfo.ruleStartAt > 60 ? 60 : threeInfo.ruleStartAt
                "
                onkeyup="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              天后产生的订单
            </div>
          </el-form-item>
          <el-form-item label=" " required>
            <div style="width: 360px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                @change="changeOrderAmmount"
                v-model="threeInfo.excludeOrderAmount"
              >
              </el-checkbox>
              针对总金额在
              <el-form-item
                prop="excludeOrderAmountSumMin"
                :rules="[
                  {
                    required: threeInfo.excludeOrderAmount == 0 ? false : true,
                    message: '请填写',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :disabled="threeInfo.excludeOrderAmount == 0"
                  v-model="threeInfo.excludeOrderAmountSumMin"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  @blur="
                    threeInfo.excludeOrderAmountSumMax =
                      threeInfo.excludeOrderAmountSumMax <
                      threeInfo.excludeOrderAmountSumMin
                        ? ''
                        : threeInfo.excludeOrderAmountSumMax
                  "
                  size="mini"
                  style="width: 70px"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>-
              <el-form-item
                prop="excludeOrderAmountSumMax"
                :rules="[
                  {
                    required: threeInfo.excludeOrderAmount == 0 ? false : true,
                    message: '请填写',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :disabled="threeInfo.excludeOrderAmount == 0"
                  v-model="threeInfo.excludeOrderAmountSumMax"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  @blur="
                    threeInfo.excludeOrderAmountSumMax =
                      threeInfo.excludeOrderAmountSumMax <
                      threeInfo.excludeOrderAmountSumMin
                        ? ''
                        : threeInfo.excludeOrderAmountSumMax
                  "
                  size="mini"
                  style="width: 70px"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              之间的订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" " prop="">
            <div style="width: 300px">
              <el-checkbox
                v-model="threeCheck.isRefund"
                @change="changeIsRefund"
              ></el-checkbox>
              <el-select
                :disabled="!threeCheck.isRefund"
                v-model="threeInfo.isRefund"
                size="mini"
                style="width: 75px; margin-left: 4px"
                placeholder="排除"
              >
                <el-option label="针对" :value="1"> </el-option>
                <el-option label="排除" :value="-1"> </el-option>
              </el-select>
              退款的订单
            </div>
          </el-form-item>
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                v-model="threeCheck.isReturn"
                @change="changeIsReturn"
              ></el-checkbox>
              <el-select
                size="mini"
                :disabled="!threeCheck.isReturn"
                style="width: 75px; margin-left: 4px"
                v-model="threeInfo.isReturn"
                placeholder="排除"
              >
                <el-option label="针对" :value="1"> </el-option>
                <el-option label="排除" :value="-1"> </el-option>
              </el-select>
              退货的订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :disabled="oneInfo.modelType != 'createNegativeFeedbackRemoval'"
                v-model="threeCheck.isCancel"
                @change="changeIsCancel"
              ></el-checkbox>
              <el-select
                :disabled="!threeCheck.isCancel"
                size="mini"
                style="width: 75px; margin-left: 4px"
                v-model="threeInfo.isCancel"
                placeholder="排除"
              >
                <el-option label="针对" :value="1"> </el-option>
                <el-option label="排除" :value="-1"> </el-option>
              </el-select>
              取消的订单
            </div>
          </el-form-item>
          <el-form-item
            label=" "
            prop="recentMailDays"
            :rules="[
              {
                required: !threeCheck.recentMailDays? false : true,
                message: '请填写',
                trigger: ['blur','change'],
              },
            ]"
          >
            <div style="width: 360px">
              <el-checkbox
                v-model="threeCheck.recentMailDays"
                @change="changeRecentMailDays"
              ></el-checkbox>
              排除任务开启前
              <el-input
                size="mini"
                :disabled="!threeCheck.recentMailDays"
                style="width: 75px; margin-left: 4px"
                v-model="threeInfo.recentMailDays"
                placeholder="天数"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              天内有过邮件往来的买家
            </div> </el-form-item
          ><br />
          <el-form-item
            label=" "
            prop="repeatBuyerPeriod"
            :rules="[
              {
                required: threeCheck.repeatBuyerPeriod == 0 ? false : true,
                message: '请填写',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <div style="width: 300px">
              <el-checkbox
                v-model="threeCheck.repeatBuyerPeriod"
                @change="changeRepeatBuyerPeriod"
              ></el-checkbox>
              排除
              <el-input
                size="mini"
                :disabled="!threeCheck.repeatBuyerPeriod"
                style="width: 75px; margin-left: 4px"
                v-model="threeInfo.repeatBuyerPeriod"
                placeholder="天数"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
              天内重复购买的买家
            </div>
          </el-form-item>
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeShippingDiscount"
              ></el-checkbox>
              排除运费有折扣的订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeOrderDiscount"
              ></el-checkbox>
              排除商品有折扣的订单
            </div>
          </el-form-item>
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeRequestReview"
              ></el-checkbox>
              排除已请求过request a review的订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeBadReview"
              ></el-checkbox>
              排除review中差评的订单
            </div>
          </el-form-item>
          <el-form-item label=" ">
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeSurveyOrder"
              ></el-checkbox>
              排除测评订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" " v-if="title == '亚马逊'">
            <div style="width: 300px">
              <el-checkbox
                :disabled="oneInfo.modelType == 'createNegativeFeedbackRemoval'"
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeHasRequestNegativeFeedbackRemoval"
              ></el-checkbox>
              排除已发送过请求移除差评的订单
            </div>
          </el-form-item>
          <el-form-item
            label=" "
            v-if="
              oneInfo.modelType == 'confirmCustomizationDetails' &&
              title == '亚马逊'
            "
          >
            <div style="width: 300px">
              <el-checkbox
                :disabled="
                  oneInfo.modelType ==
                  'excludeHasRequestNegativeFeedbackRemoval'
                "
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeHasRequestCustomizationDetail"
              ></el-checkbox>
              排除已发送过确认定制信息的订单
            </div>
          </el-form-item>
          <el-form-item
            label=" "
            v-if="
              oneInfo.modelType == 'createConfirmOrderDetails' &&
              title == '亚马逊'
            "
          >
            <div style="width: 300px">
              <el-checkbox
                :disabled="
                  oneInfo.modelType ==
                  'excludeHasRequestNegativeFeedbackRemoval'
                "
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeHasConfirmOrderDetail"
              ></el-checkbox>
              排除已发送过确认订单详情的订单
            </div>
          </el-form-item>
          <el-form-item
            label=" "
            v-if="
              oneInfo.modelType == 'createUnexpectedProblem' &&
              title == '亚马逊'
            "
          >
            <div style="width: 300px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="threeInfo.excludeHasRequestUnexpectedProblem"
              ></el-checkbox>
              排除已发送过意外问题说明的订单
            </div>
          </el-form-item>
          <el-form-item
            v-if="
              oneInfo.modelType == 'createNegativeFeedbackRemoval' &&
              title == '亚马逊'
            "
            label=" "
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
            prop="feedbackRating"
          >
            <div style="width: 340px">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                @change="threeInfo.feedbackRating = []"
                v-model="threeCheck.feedbackRating"
              ></el-checkbox>
              针对Feedback为
              <el-select
                :disabled="!threeCheck.feedbackRating"
                style="width: 160px"
                v-model="threeInfo.feedbackRating"
                multiple
                placeholder="请选择"
              >
                <el-option value="1" label="1星"></el-option>
                <el-option value="2" label="2星"></el-option>
                <el-option value="3" label="3星"></el-option>
              </el-select>
              的订单
            </div> </el-form-item
          ><br />
          <el-form-item label=" ">
            <el-button>取消</el-button>
            <el-button @click="taskPreview">任务预览</el-button>
            <el-button @click="step--">上一步</el-button>
            <el-button @click="to4" type="primary">下一步</el-button>
          </el-form-item>
        </el-form>
        <!-- 第四部 -->
        <el-form
          v-show="step == 3"
          style="width: 70%; margin: 20px auto"
          :inline="true"
          ref="fourInfo"
          :model="fourInfo"
          label-width="80px"
          :hide-required-asterisk="true"
        >
          <el-form-item
            label=" "
            v-if="
              threeInfo.ruleOrderSendType == 'FBA' ||
              threeInfo.ruleOrderSendType == 'ALL'
            "
            prop="fbaSendDays"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <h3 style="line-height: 50px">亚马逊发货的订单(FBA)</h3>
            <el-input
              onkeyup="value=value.replace(/[^\d]/g,'')"
              placeholder="输入天数"
              v-model="fourInfo.fbaSendDays"
            >
              <el-select
                v-model="fourInfo.fbaSendType"
                style="width: 100px"
                slot="prepend"
              >
                <el-option value="buyTime" label="买家下单"></el-option>
                <el-option value="orderShip" label="订单发货"></el-option>
                <el-option value="orderArrival" label="订单到货"></el-option>
              </el-select>
              <span slot="append">天</span>
            </el-input> </el-form-item
          ><br />
          <el-form-item
            label=" "
            v-if="
              threeInfo.ruleOrderSendType == 'FBM' ||
              threeInfo.ruleOrderSendType == 'ALL'
            "
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            prop="fbmSendDays"
          >
            <h3 style="line-height: 50px">卖家发货的订单(FBM)</h3>
            <el-input
              v-model="fourInfo.fbmSendDays"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              placeholder="输入天数"
            >
              <el-select
                v-model="fourInfo.fbmSendType"
                style="width: 100px"
                slot="prepend"
              >
                <el-option value="buyTime" label="买家下单"></el-option>
                <el-option value="orderShip" label="订单发货"></el-option>
              </el-select>
              <span slot="append">天</span>
            </el-input> </el-form-item
          ><br />
          <el-form-item
          label=" "
          >
            站点时间
            <!-- <el-time-picker
              value-format="HH:mm"
              is-range
              v-model="fourInfo.time"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            >
            </el-time-picker> -->
            <el-form-item
            prop="sendDatetimeLocaleMin"
            :rules="[{required:true,message:'请选择',trigger:'change'}]">
              <el-time-select
              @blur="getTime"
                placeholder="开始时间"
                v-model="fourInfo.sendDatetimeLocaleMin"
                :picker-options="{
                  start: '01:00',
                  step: '01:00',
                  end: '23:00'
                }">
              </el-time-select>
            </el-form-item>
              <el-form-item 
              prop="sendDatetimeLocaleMax"
            :rules="[{required:true,message:'请选择',trigger:'change'}]">
                <el-time-select
                @blur="getTime"
                  placeholder="结束时间"
                  v-model="fourInfo.sendDatetimeLocaleMax"
                  :picker-options="{
                    start: '01:00',
                    step: '01:00',
                    end: '23:00',
                    minTime:fourInfo.sendDatetimeLocaleMin
                  }">
                </el-time-select>
              </el-form-item>
            发送邮件 
            <span style="color:red" v-if="localTime!=''">{{localTime}}</span>
            </el-form-item><br />
          <el-form-item label=" ">
            <el-button>取消</el-button>
            <el-button @click="taskPreview">任务预览</el-button>
            <el-button @click="step--">上一步</el-button>
            <el-button type="primary" @click="sure" :loading="loading"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites";
import TimeQuantum from "@/components/TimeQuantum";
import GoodsImage from '@/components/GoodsImage'
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quill from "quill"; // 引入编辑器
import ImageResize from "quill-image-resize-module";
import {
  titleConfig,
  editorOption,
  addInit,
  getMailAccount,
  careMail,
  chooseOrdersPage,
  checkAsin,
  batchUpdate,
  sendTestMail,
  updateInit,
  careMailUpdate,
  preview,
  tableSendView,
  getZoneTimeBycountryCode
} from "@/api/Mail/mailRules.js";
import {
  pageInit, // 查询初始化
  listPage
} from "@/api/InventoryManage/stockControl.js";
quill.register("modules/imageResize", ImageResize);
import {
  tagListPage,
  sendMsgInit,
  sendMsg,
} from "@/api/Mail/mailMessage.js";
import { getPageInit, getListPage } from "@/api/Mail/mailRules.js";
export default {
  components: {
    ShopsAndSites,
    TimeQuantum,
    quillEditor,
    GoodsImage
  },
  data() {

    var ruleContent = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入'));
        return
      } 
      let num = 0
      const arr = ['remove','delete','update','feedback','review','comment','promotion','free','cheap','important','addition','information']
      for(var item of arr){
        if(value.indexOf(item)>0){
          callback(new Error(`请勿使用敏感词${item}`));
          num++
          return 
        }
      }
      
      if(num>0) return 
      callback()
    }

    var token = window.sessionStorage.getItem("token");

    return {
      previewTitle: true,
      goodsBox: false,
      writeOrder: false,
      sendTestBox: false,
      batchEnter: false,
      previewBox: false,
      tableSendView: false,
      dialogLoading: false,
      writeASIN: false,
      OrderDialog: false,
      tableData: [],
      mytoken: { token: token },
      title: "自定义",
      total: 0,
      dialogVisible: false,
      addTypeBox: false,
      step: 0,
      templateListL:[],
      CareMailTemplateSubject:[],
      oneInfo: {
        name: "",
        content: "",
        fileId: "",
        subject: "",
        modelType: "createUnexpectedProblem",
        status: "disabled"
      },
      shopId: "",
      marketPlaceId: "",
      twoInfo: {
        mailAccountId: "",
        selectType: "all",
        asins: [],
        orders: []
      },
      oneInfoRules: {
        name: [{ required: true, message: "请填写规则名称", trigger: "blur" }],
        content: [
          { validator:ruleContent, trigger: "blur" }
        ],
        subject: [
          { required: true, message: "请输入邮件主题", trigger: "blur" }
        ]
      },
      twoInfoRules: {
        mailAccountId: [
          { required: true, message: "请选择邮箱", trigger: "change" }
        ]
      },
      threeInfo: {
        getIt: 0,
        ruleOrderSendType: "FBA",
        ruleSameSendCheck: 0,
        ruleSameSendCheckDays: "",
        ruleStartAt: "",
        excludeOrderAmount: 0,
        excludeOrderAmountSumMax: "",
        excludeOrderAmountSumMin: "",
        isRefund: "",
        isReturn: "",
        isCancel: "",
        recentMailDays: "",
        repeatBuyerPeriod: "",
        excludeShippingDiscount: 0,
        excludeOrderDiscount: 0,
        excludeRequestReview: 0,
        excludeBadReview: 0,
        excludeSurveyOrder: 0,
        excludeHasRequestUnexpectedProblem: 0,
        excludeHasRequestNegativeFeedbackRemoval: 0,
        feedbackRating: [],
        excludeHasRequestCustomizationDetail: 0,
        excludeHasConfirmOrderDetail: 0
      },
      threeCheck: {
        isRefund: false,
        isReturn: false,
        isCancel: false,
        recentMailDays: false,
        repeatBuyerPeriod: false,
        feedbackRating: false
      },
      threeInfoRules: {
        getIt: [{ pattern: /^[1-9]\d*$/, message: "请勾选" }],

        ruleStartAt: [{ required: true, message: "请填写", trigger: "blur" }]
      },
      fourInfo: {
        fbaSendType: "buyTime",
        fbaSendDays: "",
        fbmSendDays: "",
        fbmSendType: "buyTime",
        sendDatetimeLocaleMax: "",
        sendDatetimeLocaleMin: "",
      },
      titleConfig: titleConfig,
      editorOption: editorOption,
      pageInfo: {
        current: 1,
        pageSize: 10,
        shopId: "",
        marketPlaceId: "",
        status: "",
        keyword: "",
        beginTime: "",
        endTime: "",
        type: "",
        createBy: "",
        updateBy: ""
      },
      careMailType: [],
      createManList: [],
      operateManList: [],
      ruleStatus: [],
      shopList: [],
      mailList: [],
      fileList: [],
      fileList2: [],
      stockpage: {
        current: 1,
        statuse: "",
        productStatusList: [],
        asin: "",
        parent_asin: "",
        searchType: "asin",
        keyword: "",
        tableData: [],
        searchList: [],
        total: "",
        asins: []
      },
      enterContent: "",
      errorNum: 0,
      successList: 0,
      inputNum: 0,
      errorList: [],
      orderListInfo: {
        current: 1,
        selectType: "orderAmazonNumber",
        keyword: "",
        orderList: [],
        total: 0,
        selectList: []
      },
      tableBatchList: [],
      loading: false,
      isUpdate: false,
      id: "",
      sendViewInfo:{
        current:1,
        pageSize:10,
        keyword:''
      },
      sendViewTotal:0,
      viewData:[],
      sendViewName:'',
      PreviewType:'',
      sendAPreview:{
        toArr:'',
        accountId:'',
        shopId:'',
        marketPlaceId:'',
        selectType:'orderAmazonNumber',
        keyword:'',
        total:0,
        current:1,
        pageSize:10,
        orderNumber:'',
        list:[],
        currency:''
      },
      accountList:[],
      SubjectNum:0,
      localTime:'',
      editorNum:0,

    };
  },
  created() {
    this.getPageInit()
  },
  computed: {
    action() {
      return process.env.VUE_APP_API_URL + "/api/file/upload/uploadFile";
    },
    action2() {
      return process.env.VUE_APP_API_URL + "/api/requestrules/importOrdersFile";
    },
  },
  watch: {
    oneInfo: {
      handler(newval, oldval) {
        if (newval.modelType == "createNegativeFeedbackRemoval") {
          this.threeInfo.isCancel = -1;
          this.threeCheck.isCancel = true;
          this.threeInfo.excludeHasRequestNegativeFeedbackRemoval = 1;
          this.oneInfo.fileId=''
          this.oneInfo.content=''
        }
      },
      deep: true,
      immediate: true
    },
    title(newval,oldval){
      if(newval=='自定义'){
        this.oneInfo.modelType='custom'
      }
    },
    fourInfo:{
      handler(newval, oldval){
        if(!newval.sendDatetimeLocaleMax||!newval.sendDatetimeLocaleMin){
          this.localTime=''
        }
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
    inputVariables(text){
      // 获取编辑器对象
      let quill = this.$refs.myQuillEditor.quill;
      // 获取编辑器光标位置
      let index = quill.selection.savedRange.index;
      // 插入文本至光标位置，
      quill.insertText(index, text);
      // 重新计算index
      index+=text.length
      // 移动光标至文本后面
      quill.setSelection(index);
    },
    getTime(){
      if(this.fourInfo.sendDatetimeLocaleMax!=''&&this.fourInfo.sendDatetimeLocaleMin!=''){
        getZoneTimeBycountryCode({minTime:this.fourInfo.sendDatetimeLocaleMin,maxTime:this.fourInfo.sendDatetimeLocaleMax,marketPlaceId:this.marketPlaceId}).then(res=>{
          console.log(res);
          this.localTime=res.data.data
        })
      }
    },
    sendTestMail(){
      this.dialogLoading=true
      sendTestMail({
        accountId:this.sendAPreview.accountId,
        toArr:[this.sendAPreview.toArr],
        subject:this.oneInfo.subject,
        orderNumber:this.sendAPreview.orderNumber,
        shopId:this.sendAPreview.shopId,
        marketPlaceId:this.sendAPreview.marketPlaceId,
        content:this.oneInfo.content
      }).then(res=>{
        this.dialogLoading=false
        console.log(res);
        if(res.data.code==200){
          this.$message.success(res.data.message)
          this.sendTestBoxClose()
        }else {
          this.$message.error(res.data.message)
        }

      })
    },
    onEditorFocus(e){
      this.editorNum=e.selection.savedRange.index
      console.log(e.selection.savedRange);
    },
    getSubjectNum(){
      var elInput = document.getElementsByClassName('inner')[0].childNodes[1]
      console.log(elInput);
      var startPos = elInput.selectionStart
      var endPos = elInput.selectionEnd
      this.SubjectNum=startPos
    },
    inputTemplate(info){
      console.log(info);
        if(this.SubjectNum==''){
          this.oneInfo.subject+=info
          return
        }
        var elInput = document.getElementsByClassName('inner')[0].childNodes[1]
        var result = this.oneInfo.subject.substring(0, this.SubjectNum) + info + this.oneInfo.subject.substring(this.SubjectNum)
        elInput.value = result
        this.SubjectNum = this.SubjectNum + info.length
        this.oneInfo.subject = result
    },
    sendTestBoxClose(){
      this.sendTestBox=false
      console.log(this.sendTestBox);
      this.sendAPreview={
        toArr:'',
        accountId:'',
        shopId:'',
        marketPlaceId:'',
        selectType:'orderAmazonNumber',
        keyword:'',
        total:0,
        current:1,
        pageSize:10,
        orderNumber:'',
        list:[],
        currency:''
      }
    },
    getSendAPreviewID(shopId,marketPlaceId,name,currency){
      this.sendAPreview.shopId=shopId,
      this.sendAPreview.marketPlaceId=marketPlaceId
      this.sendAPreview.currency=currency
      this.sendMsgInit()
      this.chooseOrdersPage()
    },
    sendAPreviewCurrentChange(val){
      this.sendAPreview.current=val
      this.chooseOrdersPage()
    },
    findChooseOrdersPage(){
      this.sendAPreview.current=1
      this.chooseOrdersPage()
    },
    chooseOrdersPage(){
      chooseOrdersPage({
        current:this.sendAPreview.current,
        shopId:this.sendAPreview.shopId,
        marketPlaceId: this.sendAPreview.marketPlaceId,
        [this.sendAPreview.selectType]: this.sendAPreview.keyword
      }).then(res=>{
        console.log(res);
        this.sendAPreview.total = res.data.pageInfo.total
        this.sendAPreview.list = res.data.values
      })
    },
    sendMsgInit() {
      this.dialogLoading = true;
      sendMsgInit({
        shopId:this.sendAPreview.shopId,
        marketPlaceId: this.sendAPreview.marketPlaceId
      })
        .then(res => {
          if (res.data.code == 200) {
            this.dialogLoading = false;
            this.sendTestBox=true
            this.accountList = res.data.data.accountList;
            // this.templateList = res.data.data.templateList;
          }
        })
    },
    taskPreview(){
      this.PreviewType='任务预览'

      this.$refs.threeInfo.validate(valid => {
        if (!valid) return;
        this.dialogLoading=true

        this.toProcess()
        if (this.threeInfo.ruleOrderSendType == "FBA") {
          this.fourInfo.fbmSendDays = "";
          this.fourInfo.fbmSendType = "";
        } else if (this.threeInfo.ruleOrderSendType == "FBM") {
          this.fourInfo.fbaSendDays = "";
          this.fourInfo.fbaSendType = "";
        }
        preview({
          ...this.oneInfo,
          ...this.twoInfo,
          ...this.threeInfo,
          ...this.fourInfo,
          shopId: this.shopId,
          ...this.sendViewInfo,
          feedbackRating:this.threeInfo.feedbackRating.length>0?this.threeInfo.feedbackRating.join(','):'',
          marketPlaceId: this.marketPlaceId,
        }).then(res=>{
          this.dialogLoading=false
          this.tableSendView=true
          this.viewData=res.data.values
          this.sendViewTotal=res.data.pageInfo.total
          this.tableSendView=true
        })
      });
    },
    tableSendShow(id,name){
      this.PreviewType='发送预览'
      this.id=id
      this.loading=true
      this.sendViewName=name
      tableSendView({
        id:this.id,
        ...this.sendViewInfo,
        shopId:this.pageInfo.shopId,
        marketPlaceId:this.pageInfo.marketPlaceId
      }).then(res=>{
        console.log(res);
        this.loading=false
        this.viewData=res.data.values
        this.sendViewTotal=res.data.pageInfo.total
        this.tableSendView=true
      })
    },
    findSendView(){
      this.sendViewInfo.current=1
      this.tableSendShow(this.id,this.name)
    },
    sendViewCurrentChange(val){
      this.sendViewInfo.current=val
      this.tableSendShow(this.id,this.sendViewName)
      if(this.previewTitle=='发送预览'){
        this.sendViewInfo.current=val
        this.tableSendShow(this.id,this.sendViewName)
      }else {
        this.sendViewInfo.current=val
        this.taskPreview()
      }
    },
    tableSendViewClose(){
      this.sendViewInfo={
        current:1,
        pageSize:10,
        keyword:''
      }
      this.tableSendView=false
      this.viewData=[]
    },
    sendViewSizeChange(val){
      this.sendViewInfo.pageSize=val
      this.tableSendShow(this.id,this.sendViewName)
      if(this.previewTitle=='发送预览'){
        this.tableSendShow(this.id,this.sendViewName)
      }else {
        this.taskPreview()
      }
    },
    getUpdateInit(id,info) {
      this.id = id;
      this.loading = true;
      if(info=='add'){
        this.isUpdate = false
      }else {
        this.isUpdate = true;
      }
      
      this.threeInfo.getIt=1
      updateInit(id).then(res => {
        console.log(res);
        this.loading = false;
        let { data } = res.data;
        this.fileList=[{name:data.fileName,url:data.fileName}]
        this.title = data.type == "custom" ? "自定义" : "亚马逊";
        for (let key in this.oneInfo) {
          this.oneInfo[key] = data[key];
        }
        for (let key in this.twoInfo) {
          this.twoInfo[key] = data[key];
        }
        for (let key in this.threeInfo) {
          this.threeInfo[key] = data[key];
        }
        for (let key in this.threeCheck) {
          this.threeCheck[key] = data[key];
          if (data[key] == 0 || data[key] == "") {
            this.threeCheck[key] = false;
            this.threeInfo[key] = "";
          } else {
            this.threeCheck[key] = true;
          }
        }
        for (let key in this.fourInfo) {
          this.fourInfo[key] = data[key];
        }
        this.threeInfo.getIt = 0;
        this.shopId = data.shopId;
        this.marketPlaceId = data.marketPlaceId;
        this.getMailAccount();
        this.dialogVisible = true;
        if (data.excludeOrderAmount == 0) {
          this.threeInfo.excludeOrderAmountSumMax = "";
          this.threeInfo.excludeOrderAmountSumMin = "";
        } else {
          this.threeInfo.excludeOrderAmountSumMax =
            data.excludeOrderAmountSumMax;
          this.threeInfo.excludeOrderAmountSumMin =
            data.excludeOrderAmountSumMin;
        }
      });
    },
    changeStatuse(row) {
      console.log(row.status);
      batchUpdate({
        ids: [row.id],
        type: row.status == "enabled" ? "enabled" : "disabled"
      }).then(res => {
        if (res.data.code != 200) {
          this.$message.error(res.data.message);
          this.getListPage();
        }
      });
    },
    del(id) {
      this.tableBatchList = [id];
      this.batch("delete", "永久删除");
    },
    enterDown(e) {
      e.preventDefault();
      this.enterContent += ";";
    },
    batchselect(val) {
      this.tableBatchList = val.map(item => item.id);
    },
    batch(info, text) {
      this.$confirm(`此操作将永久${text}文件, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          batchUpdate({
            ids: this.tableBatchList,
            type: info
          }).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.getListPage();
            } else {
              this.$message.success(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    down() {
      window.location.href = "关怀邮件导入订单模板.xlsx";
    },
    batchAddOrder() {
      this.orderListInfo.selectList.forEach(item => {
        if (!this.twoInfo.orders.includes(item)) {
          this.twoInfo.orders.push(item);
        }
      });
      this.batchEnterClose();
    },
    batchEnterClose() {
      this.batchEnter = false;
      this.orderListInfo.selectList = [];
      this.errorList = [];
      this.fileList2 = [];
    },
    batchSuccess(res, file, fileList) {
      console.log(res);
      if (res.code == 200) {
        this.$message.success(res.message);
        res.data.forEach(item => {
          if (!this.orderListInfo.selectList.includes(item.amazonOrderNumber)) {
            this.orderListInfo.selectList.push(item.amazonOrderNumber);
          }
        });
      } else {
        this.$message.error(res.message);
        this.errorList = res.data;
      }
    },
    writeOrderClose() {
      this.writeOrder = false;
      (this.enterContent = ""), (this.inputNum = 0);
      this.successList = 0;
      this.errorNum = 0;
      this.errorList = [];
    },
    OrderDialogClose() {
      this.orderListInfo = {
        current: 1,
        selectType: "orderAmazonNumber",
        keyword: "",
        orderList: [],
        total: 0,
        selectList: []
      };
      this.OrderDialog = false;
    },
    addOrder() {
      this.twoInfo.orders = this.orderListInfo.selectList;
      this.OrderDialogClose();
    },
    OrderSelect(val) {
      val.forEach(item => {
        if (!this.orderListInfo.selectList.includes(item.amazonOrderNumber)) {
          this.orderListInfo.selectList.push(item.amazonOrderNumber);
        }
      });
    },
    selectOrderOpen() {
      this.orderListInfo.selectList = this.twoInfo.orders.map(item => item);
      this.selectOrder();
    },
    orderCurrentChange(val) {
      this.orderListInfo.current = val;
      this.selectOrder();
    },
    orderfind() {
      this.orderListInfo.current = 1;
      this.selectOrder();
    },
    selectOrder() {
      this.orderListInfo.orderList = this.orderListInfo.orderList;
      this.dialogLoading = true;
      chooseOrdersPage({
        current: this.orderListInfo.current,
        shopId: this.shopId,
        marketPlaceId: this.marketPlaceId,
        [this.orderListInfo.selectType]: this.orderListInfo.keyword
      }).then(res => {
        this.dialogLoading = false;
        this.OrderDialog = true;
        this.orderListInfo.total = res.data.pageInfo.total;
        this.orderListInfo.orderList = res.data.values;
        this.orderListInfo.orderList.forEach((item, index) => {
          if (this.orderListInfo.selectList.includes(item.amazonOrderNumber)) {
            this.$nextTick(() => {
              this.$refs.order.toggleRowSelection(
                this.orderListInfo.orderList[index]
              );
            });
          }
        });
      });
    },
    uploadSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      if (res.code == 200) {
        this.$message.success(res.message);
        this.oneInfo.fileId = res.data.fileId;
        this.fileList = [file];
      } else {
        this.$message.error(res.message);
      }
    },
    handleRemove() {
      this.oneInfo.fileId = "";
      this.fileList = [];
    },
    getMailAccount() {
      this.dialogLoading = true;
      getMailAccount(this.shopId, this.marketPlaceId).then(res => {
        this.dialogLoading = false;
        this.mailList = res.data.data;
      });
    },
    getAddInit() {
      this.dialogLoading = true;
      addInit().then(res => {
        console.log(res);
        this.dialogLoading = false;
        this.dialogVisible = true;
        this.CareMailTemplateSubject=res.data.data.CareMailTemplateSubject
        const arr = ['订单信息','配送信息','商品信息']
        const num = 0
        this.templateList=res.data.data.templateList.map((item,index)=>{
          return {
            id:num,
            value:arr[index],
            children:item
          }
        })
      });
    },
    changeOrderAmmount(){
      if(this.threeInfo.excludeOrderAmount==0){
        this.threeInfo.excludeOrderAmountSumMin=''
        this.threeInfo.excludeOrderAmountSumMax=''
      }
    },
    changeRepeatBuyerPeriod() {
      console.log(this.threeCheck.repeatBuyerPeriod);
      if (this.threeCheck.repeatBuyerPeriod) {
        this.threeInfo.repeatBuyerPeriod = this.threeInfo.repeatBuyerPeriod;
      } else {
        this.threeInfo.repeatBuyerPeriod = "";
      }
    },
    changeRuleSameSendCheck(){
      if(this.threeInfo.ruleSameSendCheck==0){
        this.threeInfo.ruleSameSendCheckDays=''
      }
    },
    changeRecentMailDays() {
      if (this.threeCheck.recentMailDays) {
        this.threeInfo.recentMailDays = this.threeInfo.recentMailDays;
      } else {
        this.threeInfo.recentMailDays = "";
      }
    },
    changeIsRefund() {
      console.log(this.threeCheck.isRefund);
      if (!this.threeCheck.isRefund) {
        this.threeInfo.isRefund = "";
      } else {
        this.threeInfo.isRefund = -1;
      }
    },
    changeIsReturn() {
      if (!this.threeCheck.isReturn) {
        this.threeInfo.isReturn = "";
      } else {
        this.threeInfo.isReturn = -1;
      }
    },
    changeIsCancel() {
      if (!this.threeCheck.isCancel) {
        this.threeInfo.isCancel = "";
      } else {
        this.threeInfo.isCancel = -1;
      }
    },
    to2() {
      this.$refs.oneInfo.validate(valid => {
        if (!valid) return;
        this.step++;
        console.log(this.oneInfo);
      });
    },
    to3() {
      this.$refs.twoInfo.validate(valid => {
        if (!valid) return;
        if (
          this.twoInfo.selectType == "inasin" ||
          this.twoInfo.selectType == "outasin"
        ) {
          if (this.twoInfo.asins.length == 0) {
            this.$message.warning("请选择Asin");
            return;
          }
        } else if (this.twoInfo.selectType == "appoint") {
          if (this.twoInfo.orders.length == 0) {
            this.$message.warning("请选择订单");
            return;
          }
        }
        if (this.twoInfo.selectType == "all") {
          this.twoInfo.asins = [];
          this.twoInfo.orders = [];
        } else if (this.twoInfo.selectType == "appoint") {
          this.twoInfo.asins = [];
        } else {
          this.twoInfo.orders = [];
        }
        this.step++;
      });
    },
    to4() {
      this.$refs.threeInfo.validate(valid => {
        if (!valid) return;
        this.step++;
      });
    },
    getAddId(shopId, marketPlaceId) {
      this.shopId = shopId;
      this.marketPlaceId = marketPlaceId;
      this.getMailAccount();
    },
    getID(shopId, marketPlaceId) {
      this.pageInfo.shopId = shopId;
      this.pageInfo.marketPlaceId = marketPlaceId;
      this.getPageInit();
    },
    find() {
      this.pageInfo.current = 1;
      this.getListPage();
    },
    tableSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getListPage();
    },
    tableCurrentChange(val) {
      this.pageInfo.current = val;
      this.getListPage();
    },
    getTimeSection(val) {
      console.log(val);
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
      this.current = 1;
      if (!this.shopId) return;
      this.getListPage();
    },

    // 商品
    stockPageInit() {
      this.stockpage.asins = this.twoInfo.asins.map(item => item);
      this.dialogLoading = true;
      pageInit()
        .then(res => {
          this.dialogLoading = false;
          this.goodsBox = true;
          console.log(res);
          this.stockpage.productStatusList = res.data.data.productStatusList;
          this.stockpage.searchList = res.data.data.searchList;
        })
        .then(res => {
          this.stockListPage();
        });
    },
    stockListPage() {
      this.dialogLoading = true;
      listPage({
        shopId: this.shopId,
        marketPlaceId: this.marketPlaceId,
        statuse: this.stockpage.statuse,
        [this.stockpage.searchType]: this.stockpage.keyword,
        current: this.stockpage.current
      }).then(res => {
        console.log(res);
        this.dialogLoading = false;
        this.stockpage.tableData = res.data.values;
        this.stockpage.total = res.data.pageInfo.total;
        console.log(this.$refs.goods);
        this.stockpage.tableData.forEach((item, index) => {
          if (this.stockpage.asins.includes(item.asin)) {
            this.$nextTick(() => {
              this.$refs.goods.toggleRowSelection(
                this.stockpage.tableData[index]
              );
            });
          }
        });
      });
    },
    stockCurrentChange(val) {
      this.stockpage.current = val;
      this.stockListPage();
    },
    stockfind() {
      this.stockpage.current = 1;
      this.stockListPage();
    },
    getListPage() {
      this.loading = true;
      getListPage(this.pageInfo).then(res => {
        this.loading = false;
        console.log(res);
        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
      });
    },
    getPageInit() {
      this.loading = true;
      getPageInit(this.pageInfo.shopId, this.pageInfo.marketPlaceId).then(
        res => {
          console.log(res);
          this.loading = false;
          this.careMailType = res.data.data.careMailType;
          this.createManList = res.data.data.createManList;
          this.operateManList = res.data.data.operateManList;
          this.ruleStatus = res.data.data.ruleStatus;
          this.shopList = res.data.data.shopList;
          
          if(!this.pageInfo.shopId){
            return
          }else{
            this.getListPage();
          }
        }
      );
    },
    writeASINClose() {
      this.writeASIN = false;
      (this.enterContent = ""), (this.inputNum = 0);
      this.successList = 0;
      this.errorNum = 0;
      this.errorList = [];
    },
    addWriteAsin() {
      this.dialogLoading = true;
      checkAsin({
        marketPlaceId: this.marketPlaceId,
        shopId: this.shopId,
        strAsin: this.enterContent
      }).then(res => {
        this.dialogLoading = false;
        console.log(res);
        if (res.data.code == 200) {
          this.errorList = [];
          if (this.twoInfo.asins.length > 0) {
            res.data.data.forEach(item => {
              if (!this.twoInfo.asins.includes(item)) {
                this.twoInfo.asins.push(item);
              }
            });
          } else {
            this.twoInfo.asins = res.data.data;
          }
          this.writeASINClose();
        } else {
          this.errorList = res.data.data;
        }
      });
    },
    addWriteOrder() {
      this.dialogLoading = true;
      checkAsin({
        marketPlaceId: this.marketPlaceId,
        shopId: this.shopId,
        strOrder: this.enterContent
      }).then(res => {
        this.dialogLoading = false;
        console.log(res);
        if (res.data.code == 200) {
          this.errorList = [];
          if (this.twoInfo.orders.length > 0) {
            res.data.data.forEach(item => {
              if (!this.twoInfo.orders.includes(item)) {
                this.twoInfo.orders.push(item);
              }
            });
          } else {
            this.twoInfo.orders = res.data.data;
          }
          this.writeOrderClose();
        } else {
          this.errorList = res.data.data;
        }
      });
    },
    enterOrder() {
      const list =
        this.enterContent.indexOf < 0
          ? [this.enterContent]
          : this.enterContent.split(";");
      this.inputNum = list.length;
    },
    enterASIN() {
      const reg = /^(B|b)[0-9]{1}[0-9]{1}\w{7}$/;
      if (!this.enterContent) {
        this.errorNum = 0;
        this.inputNum = 0;
        return;
      }
      const list =
        this.enterContent.indexOf < 0
          ? [this.enterContent]
          : this.enterContent.split(";");
      let num = 0;
      const arr = [];
      this.inputNum = list.length;
      list.forEach(item => {
        if (!reg.test(item)) num++;
        if (reg.test(item)) arr.push(item);
      });
      this.errorNum = num;
      this.successList = arr.join(";");
    },
    inputType(info) {
      if (info == "asin") {
        this.writeASIN = true;
      } else {
        this.writeOrder = true;
      }
    },
    selectStock(val) {
      console.log(val);
      val.forEach(item => {
        if (!this.stockpage.asins.includes(item.asin)) {
          this.stockpage.asins.push(item.asin);
        }
      });
    },
    addGoods() {
      this.twoInfo.asins = this.stockpage.asins.map(item => item);
      this.goodsBoxClose();
    },
    goodsBoxClose() {
      this.goodsBox = false;
      this.stockpage = {
        current: 1,
        statuse: "",
        productStatusList: [],
        asin: "",
        parent_asin: "",
        searchType: "asin",
        keyword: "",
        tableData: [],
        searchList: [],
        total: "",
        asins: []
      };
    },
    showAddBox(info) {
      this.title = info;
      this.addTypeBox = false;
      if (info == "亚马逊") {
        this.oneInfo.modelType = "createUnexpectedProblem";
      } else {
        this.oneInfo.modelType = "";
      }
      this.getAddInit();
    },
    autotip() {
      for (let item of this.titleConfig) {
        let tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    },
    toProcess(){
      if (this.oneInfo.modelType == "createNegativeFeedbackRemoval") {
        this.threeInfo.isCancel = -1;
        this.threeCheck.isCancel = true;
        this.threeInfo.excludeHasRequestNegativeFeedbackRemoval = 1;
        this.threeInfo.excludeHasRequestUnexpectedProblem = 0;
        this.threeInfo.excludeHasRequestCustomizationDetail = 0;
        this.threeInfo.excludeHasConfirmOrderDetail = 0;
      }
      if (this.oneInfo.modelType == "createUnexpectedProblem") {
        this.threeInfo.excludeHasRequestCustomizationDetail = 0;
        this.threeInfo.excludeHasConfirmOrderDetail = 0;
        this.threeInfo.feedbackRating = "";
        this.threeInfo.isCancel = 0;
          this.threeCheck.isCancel = false;
      }
      if (this.oneInfo.modelType == "confirmCustomizationDetails") {
        this.threeInfo.excludeHasConfirmOrderDetail = 0;
        this.threeInfo.excludeHasRequestUnexpectedProblem = 0;
        this.threeInfo.feedbackRating = "";
        this.threeInfo.isCancel = 0;
          this.threeCheck.isCancel = false;
      }
      if (this.oneInfo.modelType == "createConfirmOrderDetails") {
        this.threeInfo.excludeHasConfirmOrderDetail = 0;
        this.threeInfo.excludeHasRequestUnexpectedProblem = 0;
        this.threeInfo.feedbackRating = "";
        this.threeInfo.isCancel = 0;
          this.threeCheck.isCancel = false;
      }
      if (this.title == "自定义") {
        this.threeInfo.excludeHasConfirmOrderDetail = 0;
        this.threeInfo.excludeHasRequestUnexpectedProblem = 0;
        this.threeInfo.excludeHasRequestCustomizationDetail = 0;
        this.threeInfo.feedbackRating = "";
      }
    },
    sureUpdate() {
      this.loading = true;

      this.toProcess()
      console.log(this.fourInfo);
      if (this.threeInfo.ruleOrderSendType == "FBA") {
        this.fourInfo.fbmSendDays = "";
        this.fourInfo.fbmSendType = "";
      } else if (this.threeInfo.ruleOrderSendType == "FBM") {
        this.fourInfo.fbaSendDays = "";
        this.fourInfo.fbaSendType = "";
      }
      careMailUpdate({
        ...this.oneInfo,
        ...this.twoInfo,
        ...this.threeInfo,
        ...this.fourInfo,
        shopId: this.shopId,
        feedbackRating:this.threeInfo.feedbackRating.length>0?this.threeInfo.feedbackRating.join(','):'',
        marketPlaceId: this.marketPlaceId,
        type: this.title == "亚马逊" ? "amazon" : "custom",
        id: this.id
      }).then(res => {
        console.log(res);
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.dialogVisibleClose();
          this.getListPage();
          this.step = 0;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    toPreview(){
      this.$refs.oneInfo.validate(valid=>{
        if(!valid) return
        this.previewBox=true
      })
    },
    sure() {
      this.$refs.fourInfo.validate(valid => {
        if (!valid) return;

        if(this.isUpdate){
          this.sureUpdate()
          return
        }
        this.loading = true;
        this.toProcess()
        console.log(this.fourInfo);
        if (this.threeInfo.ruleOrderSendType == "FBA") {
          this.fourInfo.fbmSendDays = "";
          this.fourInfo.fbmSendType = "";
        } else if (this.threeInfo.ruleOrderSendType == "FBM") {
          this.fourInfo.fbaSendDays = "";
          this.fourInfo.fbaSendType = "";
        }
        careMail({
          ...this.oneInfo,
          ...this.twoInfo,
          ...this.threeInfo,
          ...this.fourInfo,
          feedbackRating:this.threeInfo.feedbackRating.length>0?this.threeInfo.feedbackRating.join(','):'',
          shopId: this.shopId,
          marketPlaceId: this.marketPlaceId,
          type: this.title == "亚马逊" ? "amazon" : "custom"
        }).then(res => {
          this.loading = false;

          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.dialogVisibleClose();
            this.getListPage();
            this.step = 0;
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },
    dialogVisibleClose() {
      this.isUpdate = false;
      // this.$refs.oneInfo.resetFields()
      this.oneInfo = {
        name: "",
        content: "",
        fileId: "",
        subject: "",
        modelType: "createUnexpectedProblem",
        status: "disabled"
      };
      this.$refs.twoInfo.resetFields();
      this.threeInfo = {
        getIt: 0,
        ruleOrderSendType: "FBA",
        ruleSameSendCheck: 0,
        ruleSameSendCheckDays: "",
        ruleStartAt: "",
        excludeOrderAmount: 0,
        excludeOrderAmountSumMax: "",
        excludeOrderAmountSumMin: "",
        isRefund: "",
        isReturn: "",
        isCancel: "",
        recentMailDays: 0,
        repeatBuyerPeriod: "",
        excludeShippingDiscount: 0,
        excludeOrderDiscount: 0,
        excludeRequestReview: 0,
        excludeBadReview: 0,
        excludeSurveyOrder: 0,
        excludeHasRequestUnexpectedProblem: 0,
        excludeHasRequestNegativeFeedbackRemoval: 0,
        feedbackRating: [],
        excludeHasRequestCustomizationDetail: 0,
        excludeHasConfirmOrderDetail: 0
      };
      this.$refs.fourInfo.resetFields();
      this.fourInfo={
        fbaSendType: "buyTime",
        fbaSendDays: "",
        fbmSendDays: "",
        fbmSendType: "buyTime",
        sendDatetimeLocaleMax: "",
        sendDatetimeLocaleMin: "",
      }
      this.dialogVisible = false;
      this.threeCheck = {
        isRefund: false,
        isReturn: false,
        isCancel: false,
        recentMailDays: false,
        repeatBuyerPeriod: false,
        feedbackRating: false
      };
      this.twoInfo.asins = [];
      this.twoInfo.orders = [];
      this.fileList = [];
      this.step = 0;
    }
  }
};
</script>

<style scoped lang='scss'>
.select:hover {
  background: #ecf5ff;
}
.Rules {
  > div {
    border: 1px solid #ebeef5;
    margin-bottom: 15px;
    width: 100%;
    text-align: left;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    > div:nth-of-type(1) {
      font-size: 40px;
    }
    > div:nth-of-type(2) {
      line-height: 18px;
      margin-left: 20px;
      h3 {
        margin-bottom: 9px;
      }
    }
  }
}
::v-deep .type .el-dialog__body {
  padding: 5px 20px;
}
::v-deep .Box .el-dialog__body {
  padding: 5px 20px;
  min-height: 600px;
}
::v-deep .ql-container {
  min-height: 400px;
}
::v-deep .el-input-group__prepend {
  background-color: #fff;
}
.asinList {
  flex-wrap: wrap;
  > div {
    background: #e6e8ed;
    border-radius: 6px;
    display: inline-block;
    padding: 0 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    > span {
      margin-right: 10px;
      padding: 2px;
    }
    > i {
      cursor: pointer;
    }
  }
}
</style>